import axios from 'axios';

// const baseURL = 'http://127.0.0.1:5000/';
const baseURL = 'https://autofuel.gr/api/prod/'

export { baseURL };

const SERVICE_TOKEN = "f518262ee1543b01c134d85643aa0507";

// Create an Axios instance with the base URL
const instance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Create a separate Axios instance for refreshing the JWT token
const refreshInstance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add request interceptor for JWT token and X-Service-Token
instance.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem('access_token');
    if (!token) {
      // Use the static, hard-coded token if no token is found in localStorage
      // autofuel.gr
      token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTczNzAzMjAxMywianRpIjoiM2RkMjNiODAtY2ViYi00MTdlLWI4MDEtMzE2NTJjMzYxNWQ4IiwidHlwZSI6ImFjY2VzcyIsInN1YiI6InVzZXJAbWFpbC5jb20iLCJuYmYiOjE3MzcwMzIwMTMsImNzcmYiOiJlZDczNmY0NC0wNmY3LTQ4NjUtYTlkNi02MTQzNTIxYmFjODkiLCJleHAiOjE3Mzk2MjQwMTMsInJvbGUiOiJzeXNhZG1pbiJ9.n9wpj3OCKqRWjhUSD24QyJsWfrtiVDyTZWvEuMxo7T8'
    // temp
    // token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTczNzU1ODA3NywianRpIjoiZDczMTc0NDgtNGY5NC00N2M3LTkxZTAtZDE2MzA3NDYwYTNhIiwidHlwZSI6ImFjY2VzcyIsInN1YiI6MTI3LCJuYmYiOjE3Mzc1NTgwNzcsImNzcmYiOiIwZDVlZjZkYi02NmUwLTQwMjYtOWMwNy03OGQzYWIzMDg5YjQiLCJleHAiOjE3NDAxNTAwNzcsInJvbGUiOiJlbmR1c2VyIiwidXNlcl9kYXRhIjp7ImlkIjoxMjcsImZ1bGxfbmFtZSI6InRlc3QgdHJpYSIsImVtYWlsIjoidGVzdDNAdGVzdDMuY29tIiwicGhvbmUiOiI2OTQ3MDAyNTczIiwiY29tcGFueV9pZCI6MSwidmVoaWNsZV9wbGF0ZXMiOlt7ImlkIjo1OCwiY29tcGFueV9pZCI6MSwidXNlcl9pZCI6MTI3LCJwbGF0ZSI6Im5pazQ3NDciLCJkcml2ZXJfbmFtZSI6IlVzZXIgRGlvIiwiZHJpdmVyX3Bob25lIjoiNjk0NzAwMjU3MyIsImRyaXZlcl9lbWFpbCI6InVzZXIyQG1haWwuY29tIiwiZnVlbF9jYXRlZ29yeSI6IjMiLCJrbV9jaGVjayI6dHJ1ZSwiY3JlYXRlZCI6IjIwMjUtMDEtMjFUMTQ6NTE6MzYiLCJ1cGRhdGVkIjoiMjAyNS0wMS0yMVQxNDo1MTozNiJ9LHsiaWQiOjU5LCJjb21wYW55X2lkIjoxLCJ1c2VyX2lkIjoxMjcsInBsYXRlIjoiYmJiMjIyMiIsImRyaXZlcl9uYW1lIjoidGVzdCB0cmlhIiwiZHJpdmVyX3Bob25lIjoiNjk0NzAwMjU3MyIsImRyaXZlcl9lbWFpbCI6InRlc3QzQHRlc3QzLmNvbSIsImZ1ZWxfY2F0ZWdvcnkiOiIzIiwia21fY2hlY2siOnRydWUsImNyZWF0ZWQiOiIyMDI1LTAxLTIyVDExOjEzOjUwIiwidXBkYXRlZCI6IjIwMjUtMDEtMjJUMTE6MTM6NTAifV0sImNvbXBhbnkiOnsiaWQiOjEsImNvZGUiOiIyMTMxOTkiLCJuYW1lIjoiQVVUT0ZVRUwiLCJzdHJlZXQiOiJQYXNzYWxpZGkiLCJzdHJlZXRfbnVtIjoiOSIsInRrIjoiNTUxMzIiLCJjaXR5IjoiS2FsYW1hcmlhIiwicHJvZmVzc2lvbiI6InByb2Zlc3Npb24iLCJhZm0iOiI5OTg4Nzc2NjUiLCJ0YXhvZmZpY2UiOiJFTDk5MTEiLCJwaG9uZV9udW0xIjoiMjMxMCIsInBob25lX251bTIiOiIiLCJjb250YWN0X3BlcnNvbiI6IiIsImNvbnRhY3RfdGVsX25vIjoiIiwibGF0aXR1ZGUiOjQwLjU3OCwibG9uZ2l0dWRlIjoyMi45NDYsImNyZWF0ZWQiOiIyMDI0LTEwLTI1VDE1OjAwOjU2IiwidXBkYXRlZCI6IjIwMjQtMTAtMjVUMTU6MDA6NTYifX19.eGkFV-Ap_u9yFGNR4aguyCPJPOHaTes_PuRzO6vAozY'
    // localhost
    // token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTczNzYzMjY2MiwianRpIjoiYWY2NWVjMTUtNGRkNi00YWM5LTkyYzMtOTI5MGIyMDRlMWEwIiwidHlwZSI6ImFjY2VzcyIsInN1YiI6InVzZXJAbWFpbC5jb20iLCJuYmYiOjE3Mzc2MzI2NjIsImNzcmYiOiJjMjJjYjk4NS0xYzJjLTQyYTUtYjk3YS04Y2NmZGM4ZWU0MjciLCJleHAiOjE5OTY4MzI2NjIsInJvbGUiOiJzeXNhZG1pbiJ9.Kj6vn4FIEMy4XUdCe89kyJfymnvrWjUwDQp3hYzu7aY';
    }
    const refreshToken = localStorage.getItem('refresh_token') || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTczNDAwODQ0OCwianRpIjoiMTNkZTNkOGYtY2NiMi00MTUxLTg2NzctMGFlMDk5YjZmOWJmIiwidHlwZSI6InJlZnJlc2giLCJzdWIiOiJ1c2VyQG1haWwuY29tIiwibmJmIjoxNzM0MDA4NDQ4LCJjc3JmIjoiNjYzMDNjNmQtNTUyMy00ZTg5LThhMWUtYjY2ZWY0NGQzMjI1IiwiZXhwIjoxNzQwMDU2NDQ4LCJyb2xlIjoic3lzYWRtaW4ifQ.L1AFPFfXxbr1mq16kgRMpoGwESqBje8G_f4zdj-XIEc';
    const serviceToken = SERVICE_TOKEN;
    if (token && !config.headers['X-Service-Token']) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (refreshToken) {
      config.headers['X-Refresh-Token'] = refreshToken;
    }
    if (serviceToken) {
      config.headers['X-Service-Token'] = serviceToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => response,
  async error => {
    console.log('Error response:', error.response);
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshToken = localStorage.getItem('refresh_token');
        if (!refreshToken) {
          return Promise.reject(error);
        }

        const refreshResponse = await refreshInstance.post('/auth/refresh', {}, {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        });

        const { access_token } = refreshResponse.data;
        localStorage.setItem('access_token', access_token);

        originalRequest.headers.Authorization = `Bearer ${access_token}`;

        return instance(originalRequest);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default instance;
